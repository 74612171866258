import { useState, useEffect } from "react";
import { FaChevronUp } from "react-icons/fa";

export const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="fixed bottom-8 right-8 z-50">
      <button
        onClick={scrollToTop}
        className={`p-3 rounded-full bg-cyan-400 hover:bg-cyan-300 text-white shadow-lg transition-all duration-300 transform ${
          isVisible
            ? "opacity-100 translate-y-0"
            : "opacity-0 translate-y-10 pointer-events-none"
        } focus:outline-none group`}
        aria-label="Scroll to top"
      >
        <FaChevronUp size={20} className="group-hover:animate-bounce" />
      </button>
    </div>
  );
};
