import { useState, useEffect } from "react";
import { FaLinkedin } from "react-icons/fa";

export const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 60) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav
      className={`w-full z-50 fixed flex items-center justify-between transition-all duration-300 px-4 py-3 
        ${
          scrolled
            ? "bg-gray-900 shadow-lg"
            : navbarOpen
            ? "bg-gray-900/95"
            : "bg-gradient-to-r from-gray-900/80 to-gray-800/80 backdrop-blur-sm"
        }`}
    >
      <div className="container mx-auto flex items-center justify-between">
        <div className="relative flex items-center">
          <p
            className="font-extrabold text-2xl text-white flex items-center transition-all duration-300 hover:text-cyan-400 cursor-pointer"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <span className="text-cyan-500 mr-1">MK</span>
            <span className="hidden sm:inline text-lg font-normal">
              | Frontend
            </span>
          </p>
        </div>

        <div className="hidden lg:flex items-center space-x-4">
          <SocialLink
            href="https://www.linkedin.com/in/majdi-kokaly/"
            title="LinkedIn"
            icon={<FaLinkedin size={18} />}
            text="LinkedIn"
            color="bg-blue-700 hover:bg-blue-800"
          />
          <SocialLink
            href="/Majdi_Kokaly_Frontend_Developer.pdf"
            title="Download CV"
            icon={
              <img
                alt="CV"
                src="/assets/profile.png"
                width={"28px"}
                className="rounded-xl"
              />
            }
            text="CV"
            color="bg-gray-700 hover:bg-gray-800"
          />
        </div>

        <div className="lg:hidden">
          <button
            className="flex items-center p-2 rounded-md text-gray-200 hover:text-white transition-colors"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <span className="sr-only">Menu</span>
            {!navbarOpen ? (
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            )}
          </button>
        </div>
      </div>
      <div
        className={`fixed inset-0 z-40 lg:hidden bg-gray-900 transform ${
          navbarOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out flex flex-col pt-20`}
        style={{ top: "60px" }}
      >
        <div className="flex flex-col space-y-4 px-8 py-6">
          <MobileSocialLink
            href="https://www.linkedin.com/in/majdi-kokaly/"
            title="LinkedIn"
            icon={<FaLinkedin size={20} />}
            onClick={() => setNavbarOpen(false)}
          />
          <MobileSocialLink
            href="/Majdi_Kokaly_Frontend_Developer.pdf"
            title="Download CV"
            icon={
              <img
                alt="CV"
                src="/assets/profile.png"
                width={"28px"}
                className="rounded-xl"
              />
            }
            onClick={() => setNavbarOpen(false)}
          />
        </div>
      </div>
    </nav>
  );
};

const SocialLink = ({ href, title, icon, text, color }) => (
  <a
    className={`flex items-center px-4 py-2 ${color} text-white rounded-full transform hover:scale-105 transition-all duration-200`}
    href={href}
    target="_blank"
    rel="noreferrer"
    title={title}
  >
    {icon}
    <span className="ml-2 font-medium">{text}</span>
  </a>
);

const MobileSocialLink = ({ href, title, icon, onClick }) => (
  <a
    href={href}
    className="flex items-center space-x-3 text-gray-300 text-xl py-4 px-2 border-b border-gray-800 hover:text-white transition-colors"
    target="_blank"
    rel="noreferrer"
    title={title}
    onClick={onClick}
  >
    <div className="bg-gray-800 p-3 rounded-full">{icon}</div>
    <span className="font-medium">{title}</span>
  </a>
);
