import { useState, useEffect } from "react";

export const Projects = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const minWindowWidth = 1537;

  return (
    <div className="w-full" id="projects-div">
      <div className="w-full py-10 text-center bg-black backdrop-filter backdrop-blur-md">
        <h2 className="text-4xl md:text-5xl font-bold text-white mb-3">
          Featured Projects
        </h2>
        <div className="w-20 h-1 bg-cyan-500 mx-auto"></div>
      </div>

      <div className="w-full">
      <div
        className={`flex flex-col ${
          windowWidth < minWindowWidth
            ? "flex-col-reverse"
            : "md:flex-row"
        } w-full bg-gray-600`}
      >
          <div
            className={`w-full ${
              windowWidth < minWindowWidth ? "" : "md:w-1/2"
            }`}
          >
            <div className="pt-[75%] md:pt-0 md:h-[450px] relative">
              <a
                target="_blank"
                href="http://www.dynatech2012.com"
                rel="noreferrer"
                className="absolute inset-0"
              >
                <img
                  alt="Dynatech2012"
                  title="Go to Dynatech2012 website"
                  src={
                    windowWidth < minWindowWidth
                      ? "/assets/dynatech2012Small.png"
                      : "/assets/dynatech2012.png"
                  }
                  className="w-full h-full object-cover"
                />
              </a>
            </div>
          </div>

          <div
            className={`w-full ${
              windowWidth < minWindowWidth ? "" : "md:w-1/2"
            } text-[#DCDCDC] p-10 flex flex-col justify-center`}
          >
            <p className="text-left w-full text-4xl">Dynatech2012</p>
            <p className="text-sm text-left">React & Firebase</p>
            <p className="text-left w-full text-lg mt-6">
              Digital partner, collaborating with clients across diverse
              industries, empowering their technology-driven ventures. Whether
              it's pioneering software or innovative hardware projects, Dynatech
              step in to transform their vision into a refined, market-ready
              reality.
            </p>
            <p className="text-left w-full text-lg mt-6">
              At Dynatech core, Dynatech prioritize transparency, ensuring clear
              expectations and delivering top-notch products on-time and within
              budget. Dynatech goal is to establish a lasting partnership with
              you, seamlessly integrating Dynatech services into your business
              journey. Let's embark on this transformative tech journey
              together.
            </p>
            <p className="mt-10 text-sm text-right">
              <a
                target="_blank"
                href="http://www.dynatech2012.com"
                rel="noreferrer"
                className="hover:text-gray-400"
              >
                www.dynatech2012.com
              </a>
            </p>
          </div>
        </div>
      </div>

      <div
        className={`flex flex-col ${
          windowWidth < minWindowWidth
            ? "flex-col-reverse"
            : "md:flex-row-reverse"
        } w-full bg-gray-800`}
      >
        <div
          className={`w-full ${windowWidth < minWindowWidth ? "" : "md:w-1/2"}`}
        >
          <div className="pt-[75%] md:pt-0 md:h-[450px] relative">
            <a
              target="_blank"
              href="http://www.plantocad.com"
              rel="noreferrer"
              className="absolute inset-0"
            >
              <img
                alt="PlantoCAD"
                title="Go to PlantoCAD website"
                src={
                  windowWidth < minWindowWidth
                    ? "/assets/plantocad.png"
                    : "/assets/plantocadBig.png"
                }
                className="w-full h-full object-cover"
              />
            </a>
          </div>
        </div>

        <div
          className={`w-full ${
            windowWidth < minWindowWidth ? "" : "md:w-1/2"
          } text-[#DCDCDC] p-10 flex flex-col justify-center`}
        >
          <p className="text-left w-full text-4xl">Plan to CAD</p>
          <p className="text-sm text-left">React & Firebase</p>
          <p className="text-left w-full text-lg mt-6">
            Team of researchers, designers and engineers who are passionate
            about using technology to enhance the process of vectorizing
            drawings. The company goal is to provide better solutions to you and
            expedite the digitization process.
          </p>
          <p className="text-left w-full text-lg mt-6">
            The specialization lies in converting drawings into
            industry-standard formats like DXF. Through the use of advanced
            technology, the company is able to accurately and rapidly convert
            certain extensions (PDF, JPG, PNG, TIFF or BMP) into DXF format,
            making it effortless for you to share and use your files.
          </p>
          <p className="mt-10 text-sm text-right">
            <a
              target="_blank"
              href="http://www.plantocad.com"
              rel="noreferrer"
              className="hover:text-gray-400"
            >
              www.plantocad.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
