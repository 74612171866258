import { ScrollToTopButton } from "./scroll-to-top-button";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { FaEnvelope, FaPhone } from "react-icons/fa";

export const Contact = () => {
  const [emailHover, setEmailHover] = useState(false);
  const [phoneHover, setPhoneHover] = useState(false);
  return (
    <div
      id="contact-div"
      style={{
        backgroundImage: 'url("/assets/bg2.jpg")',
        backgroundSize: "cover",
        minHeight: "600px",
      }}
      className="w-full h-screen bg-[rgba(0,0,0,1)] relative"
    >
      <div className="w-full py-10 text-center bg-black backdrop-filter backdrop-blur-md">
        <h2 className="text-4xl md:text-5xl font-bold text-white mb-3">
          Get in Touch
        </h2>
        <div className="w-20 h-1 bg-cyan-500 mx-auto"></div>
      </div>
      <div
        className="flex w-full pt-[5%] flex-col"
        style={{
          paddingLeft: isMobile ? "0px" : "144px",
          alignItems: isMobile ? "center" : "flex-start",
        }}
      >
        <a
          href="mailto:mjkokaly@gmail.com"
          className="w-[250px] bg-black my-6 bg-opacity-10 backdrop-filter backdrop-blur-md rounded-xl p-6 flex-1 group transition-all duration-300 hover:bg-opacity-20 hover:transform hover:scale-105"
          onMouseEnter={() => setEmailHover(true)}
          onMouseLeave={() => setEmailHover(false)}
        >
          <div className="flex items-center mb-4">
            <div className="p-3 bg-cyan-600 rounded-full mr-4 group-hover:bg-cyan-500 transition-colors">
              <FaEnvelope size={24} className="text-white" />
            </div>
            <h3 className="text-2xl font-semibold text-white">Email</h3>
          </div>
          <p
            className={`text-xl ${
              emailHover ? "text-cyan-300" : "text-gray-300"
            } transition-colors`}
          >
            mjkokaly@gmail.com
          </p>
        </a>

        <a
          href="tel:+34670381528"
          className="w-[250px] bg-black bg-opacity-10 backdrop-filter backdrop-blur-md rounded-xl p-6 flex-1 group transition-all duration-300 hover:bg-opacity-20 hover:transform hover:scale-105"
          onMouseEnter={() => setPhoneHover(true)}
          onMouseLeave={() => setPhoneHover(false)}
        >
          <div className="flex items-center mb-4">
            <div className="p-3 bg-green-600 rounded-full mr-4 group-hover:bg-green-500 transition-colors">
              <FaPhone size={24} className="text-white" />
            </div>
            <h3 className="text-2xl font-semibold text-white">Phone</h3>
          </div>
          <p
            className={`text-xl ${
              phoneHover ? "text-green-300" : "text-gray-300"
            } transition-colors`}
          >
            +34 670 38 15 28
          </p>
        </a>
      </div>
      <ScrollToTopButton />
    </div>
  );
};
