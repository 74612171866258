import { useEffect, useState } from "react";

export const Home = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  }, []);
  return (
    <div
      id="home-div"
      style={{
        backgroundImage: 'url("/assets/bg.jpg")',
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="w-full bg-black flex flex-col items-start justify-center h-screen relative overflow-hidden px-20"
    >
      <div className="absolute inset-0 bg-black bg-opacity-40"></div>
      <div
        className={`relative z-10 px-6 text-left transition-all duration-1000 ease-out ${
          isLoaded ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
        }`}
      >
        <h1 className="text-5xl md:text-7xl font-bold text-white mb-4 tracking-wide">
          MAJDI KOKALY
        </h1>
        <h2 className="text-3xl md:text-5xl font-bold text-cyan-400 tracking-wide">
          FRONTEND DEVELOPER
        </h2>
      </div>
    </div>
  );
};
